import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCartListService,
  addCartService,
  deleteCartService,
  checkoutCartService,
  saveOrderService,
  // orderNowService,
  updateCartQuantityService,
} from "../../services/cart.service";

export const getCartList = createAsyncThunk("/getCartList", async (params) => {
  return await getCartListService(params);
});

export const addCart = createAsyncThunk("/addCart", async (payload) => {
  return await addCartService(payload);
});

export const deleteCart = createAsyncThunk("/deleteCart", async (params) => {
  return await deleteCartService(params);
});

export const checkoutCart = createAsyncThunk(
  "/checkoutCart",
  async (payload, { rejectWithValue }) => {
    console.log("Triggered redux checkoutCart:", rejectWithValue);
    return await checkoutCartService(payload, rejectWithValue);
  }
);

export const updateCartQuantityAction = createAsyncThunk(
  "/updateCartQuantity",
  async (payload) => {
    const { variantId, quantity } = payload;

    return await updateCartQuantityService(variantId, { quantity });
  }
);

export const saveOrder = createAsyncThunk("/saveOrder", async (params) => {
  return await saveOrderService(params);
});

import { combineReducers } from "@reduxjs/toolkit";

import { Product } from "./slices/Product";
import { Category } from "./slices/Category";
import { Cart } from "./slices/Cart";
import { Merchant } from "./slices/Merchant";
import { Order } from "./slices/Order";
import { User } from "./slices/User";
import { ProductReviews } from "./slices/ProductReviews";
import { ProductReturn } from "./slices/ProductReturn";
import { Service } from "./slices/Service";
import { Reward } from "./slices/Reward";
import { DeliveryAddress } from "./slices/DeliveryAddress";
import { Cms } from "./slices/Cms";
import { Voucher } from "./slices/Voucher";

const rootReducer = combineReducers({
  Product,
  Category,
  Cart,
  Merchant,
  Order,
  User,
  ProductReviews,
  ProductReturn,
  Service,
  Reward,
  DeliveryAddress,
  Cms,
  Voucher
});

export default rootReducer;

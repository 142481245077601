import { createSlice } from "@reduxjs/toolkit";
import {
  orderNow,
  cancelOrder,
  orderReceived,
  getOrderHistory,
  getReturnOrders,
  getRefundOrders,
  getOrderSummary,
  getCancelReasons,
} from "../actions/Order.action";
import { groupByProperty } from "../../helpers/array_helper";
import { claimReward } from "../actions/Reward.action";

const initialState = {
  loading: false,
  success: false,
  message: null,
  error: false,
  productOrders: null,
  totalAmountOrder: null,
  orderType: "cart",
  orderSummary: null,
  currentSelectedTab: "",
  // Order History
  orderDetails: null,
  placedOrder: null,
  isOrderRecievedOpen: false,
  orderRecieved: null,
  orderRecievedSuccess: false,
  getOrderHistorySuccess: false,
  getOrderHistoryLoading: false,
  myAllOrders: null,
  // myPendingOrders: null,
  // myConfirmedOrders: null,
  // myForDeliveryOrders: null,
  // myDeliveredOrders: null,
  // myCancelledOrder: null,
  // myToReviewOrders: null,
  // myCompletedOrders: null,
  isOrderCancelReasonOpen: false,
  cancelReasons: {},
  successNew: {
    getReturnOrders: false,
    getRefundOrders: false,
  },
  loadingNew: {
    getReturnOrders: false,
    getRefundOrders: false,
  },
  errorNew: {
    getReturnOrders: false,
    getRefundOrders: false,
  },
};

const slice = createSlice({
  name: "Order",
  initialState,
  reducers: {
    setCurrentSelectedTab: (state, action) => {
      state.currentSelectedTab = action.payload;
    },
    setProductOrders: (state, action) => {
      state.productOrders = action.payload;
    },
    setTotalAmountOrder: (state, action) => {
      state.totalAmountOrder = action.payload;
    },
    setOrderType: (state, action) => {
      state.orderType = action.payload;
    },
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    setOrderReceivedOpen: (state, action) => {
      state.isOrderRecievedOpen = action.payload;
    },
    setOrderReceived: (state, action) => {
      state.orderRecieved = action.payload;
    },
    setOrderCancelReasonOpen: (state, action) => {
      state.isOrderCancelReasonOpen = action.payload;
    },
    clearOrderState: (state, action) => {
      state.success = false;
      state.error = false;
      state.message = null;
      state.orderSummary = null;
      state.orderRecievedSuccess = false;
    },
    resetState: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = false;
      state.message = null;
      state.isOrderRecievedOpen = false;
      state.orderRecieved = null;
      state.orderRecievedSuccess = false;
      state.getOrderHistorySuccess = false;
      state.myAllOrders = null;
      // state.myPendingOrders = null;
      // state.myConfirmedOrders = null;
      // state.myForDeliveryOrders = null;
      // state.myDeliveredOrders = null;
      state.placedOrder = null;
      state.productOrders = null;
      // state.myCancelledOrder = null;
      // state.myToReviewOrders = null;
      // state.myCompletedOrders = null;
    },
    clearCurrentSelectedTab: (state, action) => {
      state.currentSelectedTab = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(cancelOrder.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = false;
      })
      .addCase(cancelOrder.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      });

    builder
      .addCase(orderNow.pending, (state) => {
        state.loading = true;
      })
      .addCase(orderNow.fulfilled, (state, action) => {
        state.loading = false;
        state.productOrders = action.payload.d;
        state.success = true;
        state.error = false;
      })
      .addCase(orderNow.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      });

    builder
      .addCase(getCancelReasons.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCancelReasons.fulfilled, (state, action) => {
        state.loading = false;
        state.cancelReasons = action.payload.d;
        state.success = true;
        state.error = false;
      })
      .addCase(getCancelReasons.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      });

    builder
      .addCase(getOrderSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOrderSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.orderSummary = action.payload.d;
        state.success = true;
        state.error = false;
      })
      .addCase(getOrderSummary.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      });

    builder
      .addCase(getOrderHistory.pending, (state) => {
        state.loading = true;
        state.myAllOrders = [];
        state.getOrderHistoryLoading = true;
        // state.myPendingOrders = [];
        // state.myDeliveredOrders = [];
        // state.myConfirmedOrders = [];
        // state.myForDeliveryOrders = [];
        // state.myCancelledOrder = [];
        // state.myToReviewOrders = [];
        // state.myCompletedOrders = [];
      })
      .addCase(getOrderHistory.fulfilled, (state, action) => {
        // const myOrders = action.payload.d
        //   ? groupByProperty(action.payload.d.list, 'orderStatus.status')
        //   : null;

        state.loading = false;
        state.getOrderHistoryLoading = false;
        state.myAllOrders = action.payload.d ? action.payload.d.list : null;
        // state.myPendingOrders = myOrders.pending || [];
        // state.myDeliveredOrders = myOrders.delivered || [];
        // state.myToReviewOrders = myOrders.to_review || [];
        // state.myCompletedOrders = myOrders.completed || [];
        // state.myConfirmedOrders = myOrders.confirmed || [];
        // state.myForDeliveryOrders = myOrders.shipped || [];
        // state.myCancelledOrder = myOrders.cancelled || [];
        state.getOrderHistorySuccess = true;
      })
      .addCase(getOrderHistory.rejected, (state, action) => {
        state.loading = false;
        state.getOrderHistorySuccess = false;
        state.getOrderHistoryLoading = false;
        state.message = action.payload?.m;
      });

    builder
      .addCase(getReturnOrders.pending, (state) => {
        // state.loading = true;
        state.myAllOrders = [];
        state.loadingNew.getReturnOrders = true;
      })
      .addCase(getReturnOrders.fulfilled, (state, action) => {
        // state.loading = false;
        state.loadingNew.getReturnOrders = false;
        state.successNew.getReturnOrders = true;
        state.myAllOrders = action.payload.d ? action.payload.d.list : null;
      })
      .addCase(getReturnOrders.rejected, (state, action) => {
        // state.loading = false;
        state.errorNew.getReturnOrders = true;
        state.loadingNew.getReturnOrders = false;
        state.message = action.payload?.m;
      });

    builder
      .addCase(getRefundOrders.pending, (state) => {
        // state.loading = true;
        state.myAllOrders = [];
        state.loadingNew.getRefundOrders = true;
      })
      .addCase(getRefundOrders.fulfilled, (state, action) => {
        // state.loading = false;
        state.loadingNew.getRefundOrders = false;
        state.successNew.getRefundOrders = true;
        state.myAllOrders = action.payload.d ? action.payload.d.list : null;
      })
      .addCase(getRefundOrders.rejected, (state, action) => {
        // state.loading = false;
        state.errorNew.getRefundOrders = true;
        state.loadingNew.getRefundOrders = false;
        state.message = action.payload?.m;
      });

    builder
      .addCase(orderReceived.pending, (state) => {
        state.loading = true;
      })
      .addCase(orderReceived.fulfilled, (state, action) => {
        state.loading = false;
        state.orderRecievedSuccess = true;
        state.message = "Order successfully updated";
      })
      .addCase(orderReceived.rejected, (state, action) => {
        state.loading = false;
        state.orderRecievedSuccess = false;
        state.message = action.payload?.m;
      });

    builder
      .addCase(claimReward.pending, (state) => {
        state.loading = true;
      })
      .addCase(claimReward.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.placedOrder = action.payload.d;
        state.message = action.payload?.m || "Order placed.";
      })
      .addCase(claimReward.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.message =
          action.payload?.m ||
          "Error while claiming reward, please try again later";
      });
  },
});
export const setCurrentSelectedTab = (payload) => (dispatch) => {
  dispatch(slice.actions.setCurrentSelectedTab(payload));
};

export const setProductOrders = (payload) => (dispatch) => {
  dispatch(slice.actions.setProductOrders(payload));
};

export const setTotalAmountOrder = (payload) => (dispatch) => {
  dispatch(slice.actions.setTotalAmountOrder(payload));
};

export const setOrderType = (payload) => (dispatch) => {
  dispatch(slice.actions.setOrderType(payload));
};

export const setOrderDetails = (payload) => (dispatch) => {
  dispatch(slice.actions.setOrderDetails(payload));
};

export const setOrderReceivedOpen = (payload) => (dispatch) => {
  dispatch(slice.actions.setOrderReceivedOpen(payload));
};

export const setOrderCancelReasonOpen = (payload) => (dispatch) => {
  dispatch(slice.actions.setOrderCancelReasonOpen(payload));
};

export const setOrderReceived = (payload) => (dispatch) => {
  dispatch(slice.actions.setOrderReceived(payload));
};

export const clearOrderState = (payload) => (dispatch) => {
  dispatch(slice.actions.clearOrderState(payload));
};

export const resetOrderState = () => (dispatch) => {
  dispatch(slice.actions.resetState());
};

export const clearCurrentSelectedTab = () => (dispatch) => {
  dispatch(slice.actions.clearCurrentSelectedTab());
};

export const { reducer: Order } = slice;

export default slice;

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  success: false,
  message: null,
  error: false,
  isOpenRedeemModal: false,
}

const slice = createSlice({
  name: "Reward",
  initialState,
  reducers: {
    openRedeemModal: (state, action) => {
      state.isOpenRedeemModal = action.payload
    },
    clearState: (state, action) => {
      state.error = false
      state.success = false
      state.message = null
    },
    resetState: (state, action) => {
      state.loading = false
      state.success = false
      state.error = false
      state.message = null
      state.isOpenRedeemModal = false
    }
  },
  extraReducers: (builder) => { },
})

export const openRedeemModal = payload => dispatch => {
  dispatch(slice.actions.openRedeemModal(payload))
}

export const clearState = payload => dispatch => {
  dispatch(slice.actions.clearState(payload))
}

export const resetRewardState = () => dispatch => {
  dispatch(slice.actions.resetState())
}

export const { reducer: Reward } = slice

export default slice

import { createSlice } from "@reduxjs/toolkit"
import { bookMerchantService, getMerchantService } from "../actions/Merchant.action";

const initialState = {
  loading: false,
  show: null,
  data: [],
  book: null,
  success: false,
  message: null,
  bookingDetails: null
}

const slice = createSlice({
  name: "Service",
  initialState,
  reducers: {
    setServiceToBook: (state, action) => {
      state.book = action.payload
    },
    setServiceAppointmentDateAndTime: (state, action) => {
      state.book.appointment = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMerchantService.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMerchantService.fulfilled, (state, action) => {
        state.loading = false;
        state.show = action.payload.d;
        state.success = true;
        state.error = false;
      })
      .addCase(getMerchantService.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.message = action.payload.m;
      })

    builder
      .addCase(bookMerchantService.pending, (state) => {
        state.loading = true;
      })
      .addCase(bookMerchantService.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingDetails = action.payload?.d
        state.success = true;
        state.error = false;
      })
      .addCase(bookMerchantService.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      })

  },
})

export const { reducer: Service } = slice

export const setServiceToBook = payload => dispatch => {
  dispatch(slice.actions.setServiceToBook(payload))
}

export const setServiceAppointmentDateAndTime = payload => dispatch => {
  dispatch(slice.actions.setServiceAppointmentDateAndTime(payload))
}

export default slice

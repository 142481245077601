import { createSlice } from "@reduxjs/toolkit";
import {
  getProducts,
  showProduct,
  getServices,
} from "../actions/Product.action";

const initialState = {
  loading: false,
  show: null,
  data: [],
  productsDataRaw: [],
  success: false,
  message: null,
  quantity: 1,
  totalPayPrice: 0,
  isVariationsModalOpen: false,
  type: "PRODUCTS",
  getProductsLoading: false,
  getAllServices: {
    loading: false,
    success: false,
    error: false,
    data: [],
  },
};

const slice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    clearState: (state, action) => {
      state.error = false;
      state.success = false;
      state.message = null;
      state.quantity = 1;
    },
    setQuantity: (state, action) => {
      const quantity = action.payload;
      state.quantity = quantity;
      state.totalPayPrice = state.totalPayPrice * quantity;
    },
    setTotalPayPrice: (state, action) => {
      state.totalPayPrice = action.payload;
    },
    setVariationsModal: (state, action) => {
      state.isVariationsModalOpen = action.payload;
    },
    setSelectVariation: (state, action) => {
      const product = {
        ...state.show,
        ...state.show?.variation.find(
          (variant) => action.payload === variant.id
        ),
        variation:
          state.show?.variation?.map((variation) => ({
            ...variation,
            selected: action.payload === variation.id ? true : false,
          })) || [],
      };
      state.quantity = 1; // Change the quantity back to original
      state.show = product;
    },
    resetState: (state) => {
      console.log("TRIGGERED Product.js resetState");
      state.loading = false;
      state.show = null;
      state.data = [];
      state.success = false;
      state.message = null;
      state.estimatedDelivery = null;
      state.quantity = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServices.pending, (state) => {
        state.getAllServices.loading = true;
      })
      .addCase(getServices.fulfilled, (state, action) => {
        state.getAllServices.data = action.payload.d;
        state.getAllServices.success = true;
        state.getAllServices.error = false;
        state.getAllServices.loading = false;
      })
      .addCase(getServices.rejected, (state, action) => {
        state.getAllServices.data = null;
        state.message = action.payload?.m;
        state.getAllServices.success = false;
        state.getAllServices.error = true;
        state.getAllServices.loading = false;
      })
      .addCase(getProducts.pending, (state) => {
        state.loading = true;
        state.getProductsLoading = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.d;
        state.productsDataRaw = action.payload;
        state.success = true;
        state.error = false;
        state.getProductsLoading = false;
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
        state.getProductsLoading = false;
      })
      .addCase(showProduct.pending, (state) => {
        state.loading = true;
      })
      .addCase(showProduct.fulfilled, (state, action) => {
        // console.log("showProduct.fulfilled: ", action);
        if (action.payload.d.variation) {
          const product = {
            productName: action.payload.d.name,
            ...action.payload.d,
            productId: action.payload.d.id,
            ...action.payload.d?.variation[0],
            variation:
              action.payload.d?.variation?.map((variation, index) => ({
                ...variation,
                // selected: index === 0 ? true : false,
                selected: false, // SET DEFAULT TO FALSE upon fetching
              })) || [],
          };
          state.show = product;
        } else {
          const service = {
            ...action.payload.d,
            productName: action.payload.d.name,
            productId: action.payload.d.id,
          };
          state.show = service;
        }

        state.loading = false;

        state.success = true;
        state.error = false;
      })
      .addCase(showProduct.rejected, (state, action) => {
        state.loading = false;
        state.show = null;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      });
  },
});

export const setQuantityAction = (payload) => (dispatch) => {
  dispatch(slice.actions.setQuantity(payload));
};

export const setTotalPayPriceAction = (payload) => (dispatch) => {
  dispatch(slice.actions.setTotalPayPrice(payload));
};

export const clearProductState = () => (dispatch) => {
  dispatch(slice.actions.clearState());
};

export const resetProductState = () => (dispatch) => {
  dispatch(slice.actions.resetState());
};

export const setVariationsModal = (payload) => (dispatch) => {
  dispatch(slice.actions.setVariationsModal(payload));
};

export const setSelectVariation = (payload) => (dispatch) => {
  dispatch(slice.actions.setSelectVariation(payload));
};

export const { reducer: Product } = slice;

export default slice;

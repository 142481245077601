import { createSlice } from "@reduxjs/toolkit";
import { getUserDetails, getUserTotalCoins } from "../actions/User.action";

const initialState = {
  loading: false,
  token: null,
  success: false,
  message: null,
  data: null,
  totalCoins: 0,
};

const slice = createSlice({
  name: "User",
  initialState,
  reducers: {
    saveToken: (state, action) => {
      state.token = action.payload;
    },
    resetAuthToken: (state) => {
      state.token = null;
    },
    resetState: (state, action) => {
      state.loading = false;
      state.success = false;
      state.message = null;
      state.token = null;
      state.data = null;
      state.totalCoins = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        const data = action.payload.d;
        const fullName = data.firstName + " " + data.lastName;

        state.loading = false;
        state.data = data;
        state.data.fullName = fullName;
        state.error = false;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = true;
        state.message = action?.payload?.m;
        console.log("getUserDetails:", action);
      });

    builder
      .addCase(getUserTotalCoins.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getUserTotalCoins.fulfilled, (state, action) => {
        state.loading = false;
        state.totalCoins = action.payload?.d.totalCoins;
        state.error = false;
      })
      .addCase(getUserTotalCoins.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const { reducer: User } = slice;

export const saveAuthToken = (payload) => (dispatch) => {
  dispatch(slice.actions.saveToken(payload));
};

export const resetAuthToken = () => (dispatch) => {
  dispatch(slice.actions.resetAuthToken());
};

export const resetUserState = () => (dispatch) => {
  dispatch(slice.actions.resetState());
};

export default slice;

import { createSlice } from "@reduxjs/toolkit";
import {
  getCartList,
  addCart,
  deleteCart,
  checkoutCart,
  saveOrder,
  updateCartQuantityAction,
} from "../actions/Cart.action";

const initialState = {
  loading: false,
  show: null,
  itemToRemove: null,
  data: null,
  orderDetails: null,
  addToCartSuccess: false,
  deleteCartItemSuccess: false,
  isOpenDeleteModal: false,
  message: null,
  reduxErrorMsg: null,
  error: false,
  success: false,
  deleteCart: {
    loading: false,
    error: false,
    success: false,
  },
  updateCartQuantityAPI: {
    loading: false,
    error: false,
    success: false,
  },
};

const slice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    resetPayNowState: (state, action) => {
      state.orderDetails = null;
      state.success = false;
      state.loading = false;
      state.error = false;
      state.message = null;
      state.reduxErrorMsg = null;
    },
    addItemToRemove: (state, action) => {
      state.itemToRemove = action.payload;
    },
    clearState: (state, action) => {
      state.addToCartSuccess = false;
      state.orderDetails = null;
      state.message = "";
      state.error = false;
      state.success = false;
    },
    setCartQuantity: (state, action) => {
      const { variantId, value } = action.payload;

      state.data = state.data.map((item) => ({
        ...item,
        products: item.products.map((product) =>
          product.variantId === variantId
            ? { ...product, quantity: value }
            : product
        ),
      }));
    },
    selectProductCheckout: (state, action) => {
      console.log("selectProductCheckout TRIGGERED");
      const { cartIndex, variantId, isCheckout } = action.payload;
      const checkoutProduct = {
        ...state.data[cartIndex],
        products: state.data[cartIndex].products.map((product) =>
          product.variantId === variantId
            ? { ...product, checkout: isCheckout }
            : { ...product }
        ),
      };

      state.data[cartIndex] = checkoutProduct;
    },
    selectOrderCheckout: (state, action) => {
      const { cartIndex, isCheckout } = action.payload;
      const checkoutOrder = {
        ...state.data[cartIndex],
        products: state.data[cartIndex].products.map((product) => ({
          ...product,
          checkout: isCheckout,
        })),
      };

      state.data[cartIndex] = checkoutOrder;
    },
    setOpenDeleteModal: (state, action) => {
      state.isOpenDeleteModal = action.payload;
    },
    selectAllCheckout: (state, action) => {
      const isCheckout = action.payload;

      state.data = state.data.map((item) => ({
        ...item,
        products: item.products.map((product) => ({
          ...product,
          checkout: isCheckout,
        })),
      }));
    },
    setRemoveCheckout: (state, action) => {
      const isCheckout = action.payload;

      state.data = state.data.map((item) => ({
        ...item,
        products: item.products.map((product) => ({
          ...product,
          checkout: isCheckout,
        })),
      }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCartList.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getCartList.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.d?.list;
        state.error = false;
      })
      .addCase(getCartList.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = true;
        state.message = action?.payload?.m;
      });

    builder
      .addCase(addCart.pending, (state) => {
        state.loading = true;
        state.addToCartSuccess = false;
        state.error = false;
      })
      .addCase(addCart.fulfilled, (state, action) => {
        state.loading = false;
        state.addToCartSuccess = true;
      })
      .addCase(addCart.rejected, (state, action) => {
        state.loading = false;
        state.addToCartSuccess = false;
        state.error = true;
        state.message = action?.payload?.m;
      });

    builder
      .addCase(deleteCart.pending, (state) => {
        state.loading = true;
        state.error = false;
        // New implementation
        state.deleteCart.loading = true;
        state.deleteCart.error = false;
      })
      .addCase(deleteCart.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteCartItemSuccess = true;
        state.isOpenDeleteModal = false;
        state.error = false;
        // New implementation
        state.deleteCart.loading = false;
        state.deleteCart.error = false;
        state.deleteCart.success = true;
      })
      .addCase(deleteCart.rejected, (state, action) => {
        state.loading = false;
        state.deleteCartItemSuccess = false;
        state.error = true;
        state.message = action?.payload?.m;

        // New implementation
        state.deleteCart.loading = false;
        state.deleteCart.error = true;
        state.deleteCart.success = false;
      });

    builder
      .addCase(updateCartQuantityAction.pending, (state) => {
        // New implementation
        state.updateCartQuantityAPI.loading = true;
        state.updateCartQuantityAPI.error = false;
      })
      .addCase(updateCartQuantityAction.fulfilled, (state, action) => {
        // New implementation
        state.updateCartQuantityAPI.loading = false;
        state.updateCartQuantityAPI.error = false;
        state.updateCartQuantityAPI.success = true;
      })
      .addCase(updateCartQuantityAction.rejected, (state, action) => {
        state.message = action?.payload?.m;
        // New implementation
        state.updateCartQuantityAPI.loading = false;
        state.updateCartQuantityAPI.error = true;
        state.updateCartQuantityAPI.success = false;
      });

    builder
      .addCase(checkoutCart.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(checkoutCart.fulfilled, (state, action) => {
        console.log("YAWAAAA", action);
        state.orderDetails = action.payload.d;
        state.success = true;
      })
      .addCase(checkoutCart.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.reduxErrorMsg = action?.error?.message;
        state.message = action?.payload?.m
          ? `${action?.payload?.m}`
          : `${action?.payload}`;
        console.log("REDUX checkoutCart (rejected): ", action);
      });

    builder
      .addCase(saveOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveOrder.fulfilled, (state, action) => {
        state = {
          loading: false,
          data: action.payload,
          success: true,
        };
      })
      .addCase(saveOrder.rejected, (state, action) => {
        state = {
          loading: false,
          data: [],
          success: false,
          message: action.payload,
        };
      });
  },
});

export const { reducer: Cart } = slice;

export const setCartQuantity = (payload) => (dispatch) => {
  dispatch(slice.actions.setCartQuantity(payload));
};

export const addItemToRemoveAction = (payload) => (dispatch) => {
  dispatch(slice.actions.addItemToRemove(payload));
};

export const clearCartFlagsState = (payload) => (dispatch) => {
  dispatch(slice.actions.clearState(payload));
};

export const selectProductCheckout = (payload) => (dispatch) => {
  dispatch(slice.actions.selectProductCheckout(payload));
};

export const selectOrderCheckout = (payload) => (dispatch) => {
  dispatch(slice.actions.selectOrderCheckout(payload));
};

export const setOpenDeleteModal = (payload) => (dispatch) => {
  dispatch(slice.actions.setOpenDeleteModal(payload));
};

export const selectAllCheckout = (payload) => (dispatch) => {
  dispatch(slice.actions.selectAllCheckout(payload));
};

export const setRemoveCheckout = () => (dispatch) => {
  dispatch(slice.actions.setRemoveCheckout());
};

export const resetPayNowState = () => (dispatch) => {
  dispatch(slice.actions.resetPayNowState());
};

export default slice;

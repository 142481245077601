import { createAsyncThunk } from "@reduxjs/toolkit"
import { getUserService, getUserTotalCoinsService } from "../../services/user.service"

export const getUserDetails = createAsyncThunk(
  "getUserDetails",
  async () => {
    return await getUserService()
  }
)

export const getUserTotalCoins = createAsyncThunk(
  "getUserTotalCoins",
  async () => {
    return await getUserTotalCoinsService()
  }
)
import { get, post } from "../helpers/api_helper"

const MERCHANTS_BASE_URL = "/shop/shop-merchant-services"
const MERHANT_PRODUCT_URL = "/shop/products"
const STORE_BASE_URL = "/shop/service-store-branches"

export const getMerchantServicesService = (params, rejectWithValue) => get(`${MERHANT_PRODUCT_URL}`, { params }, rejectWithValue)
export const getMerchantServiceService = (id, rejectWithValue) => get(`${MERCHANTS_BASE_URL}/${id}`, {}, rejectWithValue)
export const getMerchantServicesBookings = () => get(`${MERCHANTS_BASE_URL}/`, {})
export const getMerchantStoreBranch = () => get(`${STORE_BASE_URL}/`, {})

export const bookMerchantServicesService = (payload, rejectWithValue) => post(`${MERCHANTS_BASE_URL}/book`, payload, {}, rejectWithValue)

import { createSlice } from "@reduxjs/toolkit"
import { getPetCategories, getProductCategories, getProductSubCategories } from "../actions/Category.action"
import { CATEGORY_TYPE } from "../../constants/category"

const initialState = {
  loading: false,
  show: null,
  services: null,
  pets: null,
  success: false,
  error: false,
  message: null,
  petCategories: null,
  subCategories: null,
  selectedCategory: null
}

const slice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload
    },
    resetState: (state) => {
      state.loading = false
      state.show = null
      state.data = null
      state.error = false
      state.message = null
      state.success = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductCategories.fulfilled, (state, action) => {
        const type = action.meta.arg.type

        state.loading = false;
        state.services = type === CATEGORY_TYPE.services ? action.payload.d?.list : state.services;
        state.pets = type === CATEGORY_TYPE.pets ? action.payload.d?.list : state.pets;
        state.success = true;
        state.error = false;
      })
      .addCase(getProductCategories.rejected, (state, action) => {
        state.loading = false;
        state.services = [];
        state.pets = [];
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      });

    builder
      .addCase(getPetCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPetCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.petCategories = action.payload.d?.list;
        state.success = true;
        state.error = false;
      })
      .addCase(getPetCategories.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.petCategories = [];
        state.message = action.payload?.m;
      });

    builder
      .addCase(getProductSubCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductSubCategories.fulfilled, (state, action) => {
        console.log('action.payload.d?.list', action.payload.d?.list)

        state.loading = false;
        state.subCategories = action.payload.d?.list;
        state.success = true;
        state.error = false;
      })
      .addCase(getProductSubCategories.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.subCategories = [];
        state.message = action.payload?.m;
      });
  },
})

export const setSelectedCategory = payload => dispatch => {
  dispatch(slice.actions.setSelectedCategory(payload))
}

export const resetCategoryState = () => dispatch => {
  dispatch(slice.actions.resetState())
}

export const { reducer: Category } = slice

export default slice

import { createSlice } from "@reduxjs/toolkit"
import { getCities, getProvince, getRegion } from "../actions/Cms.action"

const initialState = {
  loading: false,
  success: false,
  message: null,
  cities: null,
  province: null,
  region: null
}

const slice = createSlice({
  name: "Cms",
  initialState,
  reducers: {
    resetState: (state) => {
      state.loading = false
      state.success = false
      state.message = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action.payload.d.list;
        state.success = true;
        state.error = false;
      })
      .addCase(getCities.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      })

    builder
      .addCase(getProvince.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProvince.fulfilled, (state, action) => {
        state.loading = false;
        state.province = action.payload.d.list;
        state.success = true;
        state.error = false;
      })
      .addCase(getProvince.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      })

    builder
      .addCase(getRegion.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRegion.fulfilled, (state, action) => {
        state.loading = false;
        state.region = action.payload.d.list;
        state.success = true;
        state.error = false;
      })
      .addCase(getRegion.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      })
  },
})

export const { reducer: Cms } = slice

export default slice

import { del, get, post, put } from "../helpers/api_helper"

const DELIVERY_ADDRESS_BASE_URL = "/users/delivery-address"

export const getAllDeliveryAddressService = () => get(`${DELIVERY_ADDRESS_BASE_URL}`)
export const getDeliveryAddressService = (id) => get(`${DELIVERY_ADDRESS_BASE_URL}/${id}`)

export const createDeliveryAddressService = (payload, rejectWithValue) => post(`${DELIVERY_ADDRESS_BASE_URL}`, payload, {}, rejectWithValue)

export const updateDeliveryAddressService = (payload, rejectWithValue) => put(`${DELIVERY_ADDRESS_BASE_URL}/${payload._id}`, payload, {}, rejectWithValue)

export const deleteDeliveryAddressService = (id) => del(`${DELIVERY_ADDRESS_BASE_URL}/${id}`)


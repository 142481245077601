import { createSlice } from "@reduxjs/toolkit";
import {
  addProductReview,
  getProductReviewsAction,
} from "../actions/Product.action";

const initialState = {
  loading: false,
  show: null,
  data: [],
  success: false,
  error: false,
  message: null,
  isModalOpen: false,
  order: {
    orderStatus: null,
    name: "",
    quantity: 0,
    status: "",
    image: "",
    productId: null,
    orderId: null,
  },
  successNew: {
    getProductReviewsAction: false,
    addProductReview: false,
  },
  loadingNew: {
    getProductReviewsAction: false,
    addProductReview: false,
  },
  errorNew: {
    getProductReviewsAction: false,
    addProductReview: false,
    errorMsg: "",
  },
};

const slice = createSlice({
  name: "ProductReviews",
  initialState,
  reducers: {
    openProductReviewModal: (state, action) => {
      state.isModalOpen = action.payload;
    },
    addOrderToProductReviewModal: (state, action) => {
      state.order.orderId = action.payload?.orderId;
      state.order.productId = action.payload?.productId;
      state.order.orderStatus = action.payload?.orderStatus;
      state.order.name = action.payload?.name;
      state.order.quantity = action.payload?.quantity;
      state.order.image = action.payload?.image;
    },
    clearState: (state, action) => {
      state.success = false;
    },
    clearToReviewState: (state, action) => {
      state.successNew.addProductReview = false;
      state.errorNew.addProductReview = false;
      state.loadingNew.addProductReview = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductReviewsAction.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(getProductReviewsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.d;
        state.success = true;
        state.error = false;
      })
      .addCase(getProductReviewsAction.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      });
    builder
      .addCase(addProductReview.pending, (state) => {
        state.loading = true;
        state.loadingNew.addProductReview = true;
        state.success = false;
        state.successNew.addProductReview = false;
        state.error = false;
        state.errorNew.addProductReview = false;
      })
      .addCase(addProductReview.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingNew.addProductReview = false;
        state.success = true;
        state.successNew.addProductReview = true;
        state.error = false;
      })
      .addCase(addProductReview.rejected, (state, action) => {
        state.loading = false;
        state.loadingNew.addProductReview = false;
        state.success = false;
        state.successNew.addProductReview = false;
        state.error = true;
        state.errorNew.addProductReview = true;
        state.message = action.payload?.m;
        state.errorNew.errorMsg = action.payload?.m;
      });
  },
});

export const openProductReviewModal = (payload) => (dispatch) => {
  dispatch(slice.actions.openProductReviewModal(payload));
};

export const addOrderToProductReviewModal = (payload) => (dispatch) => {
  dispatch(slice.actions.addOrderToProductReviewModal(payload));
};

export const clearState = (payload) => (dispatch) => {
  dispatch(slice.actions.clearState(payload));
};

export const clearToReviewState = (payload) => (dispatch) => {
  dispatch(slice.actions.clearToReviewState(payload));
};

export const { reducer: ProductReviews } = slice;

export default slice;

import { get } from "../helpers/api_helper"

const PRODUCT_CATEGORIES_BASE_URL = "/shop/products/categories"
const PET_CATEGORIES_BASE_URL = "/shop/products/pet-categories"

export const getProductCategoriesService = params => get(PRODUCT_CATEGORIES_BASE_URL, { params })
export const getProductSubCategoriesService = (payload) => {
  const { categoryId, params } = payload
  return get(`${PRODUCT_CATEGORIES_BASE_URL}/${categoryId}/sub-category`, { params })
}
export const getPetCategoriesService = () => get(PET_CATEGORIES_BASE_URL)
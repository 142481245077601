import { get, post, put, del } from "../helpers/api_helper";

const PRODUCTS_BASE_URL = "/shop/products";

export const getProductsService = (params, rejectWithValue) =>
  get(PRODUCTS_BASE_URL, { params }, rejectWithValue);

export const getAllServices = (params, rejectWithValue) =>
  get(PRODUCTS_BASE_URL, { params }, rejectWithValue);

export const showProductService = (id) => get(`${PRODUCTS_BASE_URL}/${id}`, {});

export const getProductReviewsService = (payload, rejectWithValue) =>
  get(
    `${PRODUCTS_BASE_URL}/reviews/${payload.id}`,
    { params: { ...payload.params } },
    rejectWithValue
  );

export const reviewProductService = (payload, rejectWithValue) =>
  post(`users/shop/order-history/rating`, payload, {}, rejectWithValue);

export const returnProductService = (payload, rejectWithValue) =>
  put(
    `/users/shop/return-order/${payload.transactionId}`,
    payload?.formData,
    { headers: { "Content-type": "multipart/form-data" } },
    rejectWithValue,
    "form-data"
  );

export const getReturnOrderDetailsService = (payload, rejectWithValue) =>
  get(
    `/users/shop/order-history/return-order/${payload.transactionId}/${payload.productId}/${payload.variantId}`,
    { payload },
    rejectWithValue
  );

export const getEstimatedDeliveryService = (payload, rejectWithValue) =>
  post(`${PRODUCTS_BASE_URL}/estimate`, payload, {}, rejectWithValue);

export const getSearchProductSuggestionService = (params, rejectWithValue) =>
  get(`${PRODUCTS_BASE_URL}/search-suggestions`, { params }, rejectWithValue);

export const getSearchHistoryProductSuggestionService = (params, rejectWithValue) =>
  get(`${PRODUCTS_BASE_URL}/search-history`, { params }, rejectWithValue);


export const clearSearchHistorySuggestionService = (payload, rejectWithValue) =>
  del(`${PRODUCTS_BASE_URL}/search-history`, payload, {}, rejectWithValue);
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  loading: false,
  show: null,
  data: [],
  success: false,
  message: null
}

const slice = createSlice({
  name: "Merchant",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
  },
})

export const { reducer: Merchant } = slice

export default slice

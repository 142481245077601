
import { createAsyncThunk } from "@reduxjs/toolkit"
import { getPetCategoriesService, getProductCategoriesService, getProductSubCategoriesService } from "../../services/category.service"

export const getProductCategories = createAsyncThunk(
  "/getProductCategories",
  async (params) => {
    return await getProductCategoriesService(params)
  }
)

export const getProductSubCategories = createAsyncThunk(
  "/getProductSubCategories",
  async (params) => {
    return await getProductSubCategoriesService(params)
  }
)

export const getPetCategories = createAsyncThunk(
  "/getPetCategories",
  async () => {
    return await getPetCategoriesService()
  }
)

export const showProductCategory = () => {

}
import { createSlice } from "@reduxjs/toolkit";
import {
  returnProduct,
  getReturnOrderDetails,
  getProductReviewsAction,
} from "../actions/Product.action";

const initialState = {
  loading: false,
  show: null,
  data: [],
  success: false,
  error: false,
  message: null,
  productReturnModalOpen: false,
  productReturnFailedModalOpen: false,
  returnOrderDetailsData: [],
  order: {
    orderStatus: null,
    name: "",
    quantity: 0,
    status: "",
    image: "",
    productId: null,
    variantId: null,
    orderId: null,
    merchantName: "",
    order: "",
    orderDetails: null,
  },
  successNew: {
    getReturnProductAction: false,
    returnProduct: false,
    getReturnOrderDetails: false,
  },
  loadingNew: {
    getReturnProductAction: false,
    returnProduct: false,
    getReturnOrderDetails: false,
  },
  errorNew: {
    getReturnProductAction: false,
    returnProduct: false,
    returnProductErrorMsg: "",
    getReturnOrderDetails: false,
  },
};

const slice = createSlice({
  name: "ReturnItem",
  initialState,
  reducers: {
    openProductReturnModal: (state, action) => {
      state.productReturnModalOpen = action.payload;
    },
    openProductReturnFailedModal: (state, action) => {
      state.productReturnFailedModalOpen = action.payload;
    },
    postProductReturnModal: (state, action) => {
      state.order.orderId = action.payload?.orderId;
      state.order.productId = action.payload?.productId;
      state.order.variantId = action.payload?.variantId;
      state.order.orderStatus = action.payload?.orderStatus;
      state.order.name = action.payload?.name;
      state.order.quantity = action.payload?.quantity;
      state.order.image = action.payload?.image;
      state.order.merchantName = action.payload?.merchantName;
      state.order.order = action.payload?.product;
      state.order.orderDetails = action.payload?.orderDetails;
    },
    clearState: (state, action) => {
      state.success = false;
    },
    clearReturnItemState: (state, action) => {
      state.successNew.returnProduct = false;
      state.errorNew.returnProduct = false;
      state.loadingNew.returnProduct = false;
    },
    clearReturnItemDetailsData: (state, action) => {
      state.returnOrderDetailsData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductReviewsAction.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.error = false;
      })
      .addCase(getProductReviewsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.d;
        state.success = true;
        state.error = false;
      })
      .addCase(getProductReviewsAction.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = true;
        state.message = action.payload?.m;
      });
    builder
      .addCase(returnProduct.pending, (state) => {
        state.loadingNew.returnProduct = true;
        state.successNew.returnProduct = false;
        state.errorNew.returnProduct = false;
      })
      .addCase(returnProduct.fulfilled, (state, action) => {
        state.loadingNew.returnProduct = false;
        state.successNew.returnProduct = true;
        state.errorNew.returnProduct = false;
      })
      .addCase(returnProduct.rejected, (state, action) => {
        state.loadingNew.returnProduct = false;
        state.successNew.returnProduct = false;
        state.errorNew.returnProduct = true;
        state.errorNew.returnProductErrorMsg = action.payload?.m;
      });

    builder
      .addCase(getReturnOrderDetails.pending, (state) => {
        state.loadingNew.getReturnOrderDetails = true;
        state.successNew.getReturnOrderDetails = false;
      })
      .addCase(getReturnOrderDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successNew.getReturnOrderDetails = true;
        state.returnOrderDetailsData = action.payload?.d;
        state.error = false;
      })
      .addCase(getReturnOrderDetails.rejected, (state, action) => {
        state.loadingNew.getReturnOrderDetails = false;
        state.successNew.getReturnOrderDetails = false;
        state.errorNew.getReturnOrderDetails = true;
        state.message = action.payload?.m;
      });
  },
});

export const openProductReturnModal = (payload) => (dispatch) => {
  dispatch(slice.actions.openProductReturnModal(payload));
};


export const openProductReturnFailedModal = (payload) => (dispatch) => {
  dispatch(slice.actions.openProductReturnFailedModal(payload));
};

export const postProductReturnModal = (payload) => (dispatch) => {
  dispatch(slice.actions.postProductReturnModal(payload));
};

export const clearState = (payload) => (dispatch) => {
  dispatch(slice.actions.clearState(payload));
};

export const clearReturnItemState = (payload) => (dispatch) => {
  dispatch(slice.actions.clearReturnItemState(payload));
};

export const clearReturnItemDetailsData = (payload) => (dispatch) => {
  dispatch(slice.actions.clearReturnItemDetailsData(payload));
};

export const { reducer: ProductReturn } = slice;

export default slice;

import { createSlice } from "@reduxjs/toolkit";
import {
  createDeliveryAddress,
  deleteDeliveryAddress,
  getAllDeliveryAddress,
  getDeliveryAddress,
  updateDeliveryAddress,
} from "../actions/DeliveryAddress.action";

const initialState = {
  loading: false,
  success: false,
  getDeliveryAddressSuccess: false,
  error: false,
  message: null,
  selectedDeliveryAddress: null,
  show: null,
  deliveryAddress: null,
  defaultDeliveryAddress: null,
  isOpenRemoveDialog: false,
  deliveryAddressToDelete: null,
};

const slice = createSlice({
  name: "DeliveryAddress",
  initialState,
  reducers: {
    clearDeliveryAddressState: (state, action) => {
      state.error = false;
      state.success = false;
      state.message = null;
    },
    setIsOpenRemoveDialog: (state, action) => {
      state.isOpenRemoveDialog = action.payload;
    },
    setDeleteDeliveryAddress: (state, action) => {
      state.deliveryAddressToDelete = action.payload;
    },
    setSelectedDeliveryAddress: (state, action) => {
      state.selectedDeliveryAddress = action.payload;
    },
    resetState: (state, action) => {
      state.loading = false;
      state.show = null;
      state.data = [];
      state.success = false;
      state.error = false;
      state.message = null;
      state.selectedDeliveryAddress = null;
      state.defaultDeliveryAddress = null;
      state.deliveryAddress = null;
      state.show = null;
      state.deliveryAddressToDelete = null;
      state.isOpenRemoveDialog = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDeliveryAddress.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllDeliveryAddress.fulfilled, (state, action) => {
        const deliveryAddress = action.payload?.d?.items;
        const defaultDeliveryAddress =
          deliveryAddress.filter((item) => item.isDefault)[0] ?? null;

        state.loading = false;
        state.deliveryAddress = deliveryAddress;
        state.defaultDeliveryAddress = defaultDeliveryAddress;
        state.selectedDeliveryAddress = defaultDeliveryAddress;
        state.error = false;
      })
      .addCase(getAllDeliveryAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });

    builder
      .addCase(getDeliveryAddress.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.getDeliveryAddressSuccess = false;
      })
      .addCase(getDeliveryAddress.fulfilled, (state, action) => {
        const payload = action.payload?.d;
        console.log("Get Delivery Address Success: ", payload);
        state.loading = false;
        state.show = payload;
        state.getDeliveryAddressSuccess = true;
      })
      .addCase(getDeliveryAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.getDeliveryAddressSuccess = false;
      });

    builder
      .addCase(createDeliveryAddress.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
        state.message = null;
      })
      .addCase(createDeliveryAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload?.m || "Successfully created";
      })
      .addCase(createDeliveryAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });

    builder
      .addCase(updateDeliveryAddress.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(updateDeliveryAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(updateDeliveryAddress.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
      });

    builder
      .addCase(deleteDeliveryAddress.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.success = false;
        state.message = null;
      })
      .addCase(deleteDeliveryAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload?.m || "Successfully deleted";
      })
      .addCase(deleteDeliveryAddress.rejected, (state, action) => {
        state.loading = false;
        state.message =
          action.payload?.m ||
          "There's something wrong while removing delivery address. Please try again";
        state.error = true;
      });
  },
});

export const clearDeliveryAddressState = () => (dispatch) => {
  dispatch(slice.actions.clearDeliveryAddressState());
};

export const setIsOpenRemoveDialog = (payload) => (dispatch) => {
  dispatch(slice.actions.setIsOpenRemoveDialog(payload));
};

export const setDeleteDeliveryAddress = (payload) => (dispatch) => {
  dispatch(slice.actions.setDeleteDeliveryAddress(payload));
};

export const setSelectedDeliveryAddress = (payload) => (dispatch) => {
  dispatch(slice.actions.setSelectedDeliveryAddress(payload));
};

export const resetDeliveryAddressState = () => (dispatch) => {
  dispatch(slice.actions.resetState());
};

export const { reducer: DeliveryAddress } = slice;

export default slice;

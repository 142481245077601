import PropTypes from 'prop-types';
import React from "react";
import { Routes, Route } from "react-router-dom";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import scss
import PublicLayout from './components/templates/PublicLayout';
import ProtectedLayout from './components/templates/ProtectedLayout';
import Authmiddleware from './routes/route';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {

  return (
    <React.Fragment>
      <Routes>
        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <ProtectedLayout>{route.component}</ProtectedLayout>
              </Authmiddleware>}
            key={idx}
            exact={true}
          />
        ))}

        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <PublicLayout>{route.component}</PublicLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;
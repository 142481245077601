import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cancelOrderService,
  orderReceivedService,
  getOrderHistoryService,
  getReturnOrderService,
  getRefundOrderService,
  getOrderSummaryService,
  getCancelReasonsService,
} from "../../services/order.service";
import { orderNowService } from "../../services/cart.service";

export const orderNow = createAsyncThunk("/orderNow", async (params) => {
  return await orderNowService(params);
});

export const getOrderSummary = createAsyncThunk(
  "/getOrderSummary",
  async (params) => {
    return await getOrderSummaryService(params);
  }
);

export const getOrderHistory = createAsyncThunk(
  "/getOrderHistory",
  async (params) => {
    return await getOrderHistoryService(params);
  }
);

export const getReturnOrders = createAsyncThunk(
  "/getReturnOrders",
  async (params) => {
    return await getReturnOrderService(params);
  }
);

export const getRefundOrders = createAsyncThunk(
  "/getRefundOrders",
  async (params) => {
    return await getRefundOrderService(params);
  }
);

export const orderReceived = createAsyncThunk(
  "/orderReceived",
  async (params, { getState, rejectWithValue }) => {
    return await orderReceivedService(params, rejectWithValue);
  }
);

export const getCancelReasons = createAsyncThunk(
  "/getCancelReasons",
  async (params) => {
    return await getCancelReasonsService(params);
  }
);

export const cancelOrder = createAsyncThunk(
  "/cancelOrder",
  async (payload, { getState, rejectWithValue }) => {
    return await cancelOrderService(payload, rejectWithValue);
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit"
import { getMerchantServicesService, bookMerchantServicesService, getMerchantServiceService } from "../../services/merchant.service"

export const getMerchantService = createAsyncThunk(
  "/getMerchantService",
  async (params, { rejectWithValue }) => {
    return await getMerchantServiceService(params, rejectWithValue)
  }
)

export const getMerchantServices = createAsyncThunk(
  "/getMerchantServices",
  async (params, { rejectWithValue }) => {
    return await getMerchantServicesService(params, rejectWithValue)
  }
)

export const bookMerchantService = createAsyncThunk(
  "/bookMerchantService",
  async (payload, { rejectWithValue }) => {
    return await bookMerchantServicesService(payload, rejectWithValue)
  }
)

import axios from "axios";
import { store } from "../store";

export const getToken = () => {
  const state = store.getState();

  axiosApi.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${state.User.token}`;

  axiosApi.defaults.headers.common["Ocp-Apim-Subscription-Key"] =
    process.env.REACT_APP_SUBSCRIPTION_KEY;
};

//apply base url for axios
const API_URL = process.env.REACT_APP_API_BASE_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}, rejectWithValue) {
  getToken();
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data).catch((error) => rejectWithValue(error.response.data || "Error"));
}

export async function post(url, data, config = {}, rejectWithValue) {
  getToken();
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data)
    .catch((error) => {
      console.log("POST API_HELPER: ", error);
      const transformedErrResData =
        typeof error.response.data === "object"
          ? JSON.stringify(error.response.data)
          : error.response.data;
      return rejectWithValue(
        `${error.response.data ? transformedErrResData : "Error"}. ${
          error.response.status ?? ""
        }. ${error.response.statusText ?? ""}`
      );
    });
}

export async function put(url, data, config = {}, rejectWithValue, type) {
  getToken();
  return axiosApi
    .put(url, type === "form-data" ? data : { ...data }, { ...config })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data));
}

export async function del(url, config = {}) {
  getToken();
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

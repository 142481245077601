import React, { Suspense, lazy } from "react";
import styled from "styled-components";

const Loader = () => {
  return (
    <StyledLoader className="loader">
      <span></span>
      <span></span>
    </StyledLoader>
  );
};

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<Loader></Loader>}>
      <Component {...props} />
    </Suspense>
  );

const CategorySinglePage = Loadable(
  lazy(() => import("../pages/category/[id]/index"))
);

const CartPage = Loadable(lazy(() => import("../pages/cart/index")));

const OrderPaymentPage = Loadable(lazy(() => import("../pages/order/payment")));

const OrderPlacedPage = Loadable(
  lazy(() => import("../pages/order/order-placed"))
);

const OrderDeliveryPage = Loadable(
  lazy(() => import("../pages/order/delivery"))
);

const MyOrderHistoryPage = Loadable(
  lazy(() => import("../pages/order/order-history/index"))
);

const MyOrdersPage = Loadable(
  lazy(() => import("../pages/order/my-orders/index"))
);

const MyOrderTrackingPage = Loadable(
  lazy(() => import("../pages/order/my-orders/[id]/index"))
);

const MyOrderCancelledDetailsPage = Loadable(
  lazy(() => import("../pages/order/my-orders/[id]/OrderDetailsCancelled"))
);

const MyOrderReturnDetailsPage = Loadable(
  lazy(() => import("../pages/order/my-orders/[id]/OrderDetailsReturn"))
);

const MyCancelOrderPage = Loadable(
  lazy(() => import("../pages/order/my-orders/[id]/cancel"))
);

const OrderProductsReturnPage = Loadable(
  lazy(() => import("../pages/products/return"))
);

const ReturnRequestFormPage = Loadable(
  lazy(() => import("../pages/products/ReturnRequestFormPage"))
);

const MyCancelOrderSuccessPage = Loadable(
  lazy(() => import("../pages/order/my-orders/[id]/cancel/success"))
);

const MyOrderRefundSuccessPage = Loadable(
  lazy(() => import("../pages/order/my-orders/[id]/refund/success"))
);

const ProfilePage = Loadable(lazy(() => import("../pages/profile/index")));

const SavedAddressPage = Loadable(
  lazy(() => import("../pages/profile/saved-address"))
);

const Authentication = Loadable(lazy(() => import("../pages/auth/index")));

// Products
const ProductsDetailPage = Loadable(
  lazy(() => import("../pages/products/[id]/index"))
);

const ProductsDeliveryPage = Loadable(
  lazy(() => import("../pages/products/delivery"))
);

// Services
const ServicesDetailsPage = Loadable(
  lazy(() => import("../pages/services/[id]/index"))
);

const ServicesBookShortTimePage = Loadable(
  lazy(() => import("../pages/services/[id]/book-short-time"))
);

const ServicesBookLongTimePage = Loadable(
  lazy(() => import("../pages/services/[id]/book-long-time"))
);

const ServicesBookDetailsShortPage = Loadable(
  lazy(() => import("../pages/services/[id]/book-details-short"))
);

const ServicesBookDetailsLongPage = Loadable(
  lazy(() => import("../pages/services/[id]/book-details-long"))
);

// Rewards
const RewardsShopPage = Loadable(lazy(() => import("../pages/rewards/index")));

const RewardsDetailsPage = Loadable(
  lazy(() => import("../pages/rewards/[id]"))
);

const RewardsDeliveryPage = Loadable(
  lazy(() => import("../pages/rewards/[id]/delivery"))
);

// New
const ShopHomePage = Loadable(lazy(() => import("../pages/index")));

const AddDeliveryAddressPage = Loadable(
  lazy(() => import("../pages/delivery-address/add"))
);

const EditDeliveryAddressPage = Loadable(
  lazy(() => import("../pages/delivery-address/[id]/edit"))
);

const authProtectedRoutes = [
  { path: "/shop", component: <ShopHomePage /> },
  { path: "/shop/category/:categoryId", component: <CategorySinglePage /> },
  { path: "/cart", component: <CartPage /> },
  { path: "/order/payment", component: <OrderPaymentPage /> },
  { path: "/order/order-placed", component: <OrderPlacedPage /> },
  { path: "/order/delivery", component: <OrderDeliveryPage /> },
  { path: "/order/my-orders", component: <MyOrdersPage /> },
  { path: "/order/my-orders/:id", component: <MyOrderTrackingPage /> },
  {
    path: "/order/my-orders/cancelled-details/:id",
    component: <MyOrderCancelledDetailsPage />,
  },
  {
    path: "/order/my-orders/return-details/:transactionId",
    component: <MyOrderReturnDetailsPage />,
  },
  {
    path: "/order/my-orders/request-form/:transactionId",
    component: <ReturnRequestFormPage />,
  },
  { path: "/order/my-orders/:id/cancel", component: <MyCancelOrderPage /> },
  { path: "/order/my-orders/return", component: <OrderProductsReturnPage /> },
  { path: "/order/order-history", component: <MyOrderHistoryPage /> },
  { path: "/profile", component: <ProfilePage /> },
  { path: "/profile/saved-address", component: <SavedAddressPage /> },

  // Products
  { path: "/shop/products/:id", component: <ProductsDetailPage /> },
  { path: "/shop/products/delivery", component: <ProductsDeliveryPage /> },

  // Services
  { path: "/shop/services/:id", component: <ServicesDetailsPage /> },
  {
    path: "/shop/services/:id/book-short-time",
    component: <ServicesBookShortTimePage />,
  },
  {
    path: "/shop/services/:id/book-long-time",
    component: <ServicesBookLongTimePage />,
  },
  {
    path: "/shop/services/:id/book-details-short",
    component: <ServicesBookDetailsShortPage />,
  },
  {
    path: "/shop/services/:id/book-details-long",
    component: <ServicesBookDetailsLongPage />,
  },

  // Rewards
  { path: "/shop/rewards", component: <RewardsShopPage /> },
  { path: "/shop/rewards/:id", component: <RewardsDetailsPage /> },
  { path: "/shop/rewards/:id/delivery", component: <RewardsDeliveryPage /> },

  // Delivery Address
  { path: "/delivery-address/add", component: <AddDeliveryAddressPage /> },
  {
    path: "/delivery-address/:id/edit",
    component: <EditDeliveryAddressPage />,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  // {
  //   path: "/",
  //   exact: true,
  //   component: <Navigate to="/authenticate" />,
  // },
];

const publicRoutes = [{ path: "/", component: <Authentication /> }];

const StyledLoader = styled("div")``;

export { authProtectedRoutes, publicRoutes };

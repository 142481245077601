import { createSlice } from "@reduxjs/toolkit"
import { claimVoucherByMerchant } from "store/actions/Voucher.action"

const initialState = {
  loading: false,
  success: false,
  message: null,
  error: false,
  data: null,
  // isVoucherUse: false
}

const slice = createSlice({
  name: "Voucher",
  initialState,
  reducers: {
    // setIsUseVoucher: (state, action) => {
    //   state.isVoucherUse = !state.isVoucherUse
    // },
    resetStateVoucher: (state, action) => {
      state.loading = false
      state.success = false
      state.error = false
      state.message = null
      state.data = null
    }
  },
  extraReducers: (builder) => { 
    builder
      .addCase(claimVoucherByMerchant.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(claimVoucherByMerchant.fulfilled, (state, action) => {
        const data = action.payload.d;

        state.loading = false;
        state.data = data;
        state.error = false;
      })
      .addCase(claimVoucherByMerchant.rejected, (state, action) => {
        state.loading = false;
        state.data = null;
        state.error = true;
        state.message = action?.payload?.m;
        console.log("claimVoucherByMerchant:", action);
      });
  },
})

export const { reducer: Voucher } = slice

export const setIsUseVoucher = (payload) => (dispatch) => {
  dispatch(slice.actions.setIsUseVoucher(payload));
};

export const resetStateVoucher = (payload) => (dispatch) => {
  dispatch(slice.actions.resetStateVoucher(payload));
};



export default slice

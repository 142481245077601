import { get, post, put } from "../helpers/api_helper";

const USERS_BASE_URL = "/users";

export const getReturnOrderService = (params) =>
  get(`${USERS_BASE_URL}/shop/order-history/return-order`, { params });

export const getRefundOrderService = (params) =>
  get(`${USERS_BASE_URL}/shop/order-history/return-order`, { params });

export const getOrderHistoryService = (params) =>
  get(`${USERS_BASE_URL}/shop/order-history`, { params });
export const getOrderSummaryService = (id) =>
  get(`${USERS_BASE_URL}/shop/order-history/${id}`, {});

export const orderReceivedService = (payload, rejectWithValue) =>
  post(`${USERS_BASE_URL}/shop/order/delivered`, payload, {}, rejectWithValue);

export const getCancelReasonsService = (params) =>
  get(`/cms/cancel-reasons`, params);

export const cancelOrderService = (payload, rejectWithValue) =>
  put(
    `${USERS_BASE_URL}/shop/cancel-order/${payload?.id}`,
    payload?.payload,
    {},
    rejectWithValue
  );

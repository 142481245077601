import { del, get, post, put } from "../helpers/api_helper"

const CARTS_BASE_URL = "/shop/carts"

export const getCartListService = params => get(CARTS_BASE_URL, params)

export const addCartService = payload => post(CARTS_BASE_URL, payload)
export const checkoutCartService = (payload, rejectWithValue) => post(`${CARTS_BASE_URL}/checkout`, payload, {}, rejectWithValue)
export const saveOrderService = payload => post(`${CARTS_BASE_URL}/order`, payload)
export const orderNowService = payload => post(`${CARTS_BASE_URL}/order-summary-preview`, payload)

export const updateCartQuantityService = (variantId, payload) => put(`${CARTS_BASE_URL}/update-quantity/${variantId}/`, payload)

export const deleteCartService = id => del(`${CARTS_BASE_URL}/${id}`, {})
import { createAsyncThunk } from "@reduxjs/toolkit"
import { createDeliveryAddressService, deleteDeliveryAddressService, getAllDeliveryAddressService, getDeliveryAddressService, updateDeliveryAddressService } from "../../services/delivery-address.service"

export const getAllDeliveryAddress = createAsyncThunk(
  "getAllDeliveryAddress",
  async () => {
    return await getAllDeliveryAddressService()
  }
)

export const getDeliveryAddress = createAsyncThunk(
  "getDeliveryAddress",
  async (id) => {
    return await getDeliveryAddressService(id)
  }
)

export const createDeliveryAddress = createAsyncThunk(
  "createDeliveryAddress",
  async (payload) => {
    return await createDeliveryAddressService(payload)
  }
)

export const updateDeliveryAddress = createAsyncThunk(
  "updateDeliveryAddress",
  async (payload) => {
    return await updateDeliveryAddressService(payload)
  }
)

export const deleteDeliveryAddress = createAsyncThunk(
  "deleteDeliveryAddress",
  async (id) => {
    return await deleteDeliveryAddressService(id)
  }
)
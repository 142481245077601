import { createAsyncThunk } from "@reduxjs/toolkit"
import { getCitiesService, getProvinceService, getRegionService } from "../../services/cms.service"

export const getCities = createAsyncThunk(
  "/getCities",
  async (payload, { rejectWithValue }) => {
    return await getCitiesService(payload, rejectWithValue)
  }
)

export const getProvince = createAsyncThunk(
  "/getProvince",
  async (payload, { rejectWithValue }) => {
    return await getProvinceService(payload, rejectWithValue)
  }
)

export const getRegion = createAsyncThunk(
  "/getRegion",
  async (payload, { rejectWithValue }) => {
    return await getRegionService(payload, rejectWithValue)
  }
)